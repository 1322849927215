import React, { useEffect, } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import {gsap} from 'gsap'

const ContentPage = () => {

  useEffect(() => {    
    const tl = gsap.timeline();
    tl.fromTo('.page',{opacity:0.5, x:-10 }, {opacity: 1 , x: 0  }); 
   }, []);

    const data = useStaticQuery(graphql`
    query ContentPageQuery {
    allMdx(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            title
            order
            description
            category
            embeddedImagesLocal {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
            embeddedImagesRemote {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
          }
          timeToRead
        }
      }
    }
    
  }
  `)

const posts = data.allMdx.edges

    return (
<Layout>
    <div className="w-100 mw-1000 center pa2  page">
    <div className="grid-columns">

        {posts.map(({ node, i }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  const excerpt = node.frontmatter.description ? node.frontmatter.description.substring(0, 100) + '...' : node.excerpt
                  const imageToUse = node.frontmatter.embeddedImagesLocal ? node.frontmatter.embeddedImagesLocal[0] : node.frontmatter.embeddedImagesRemote[0] 

         
          return (
              
            <Link key={node.id} className="link black-800 pointer" to={`/${node.slug}`}>
                <div className="bg-white shadow-5 br-1 overflow-hidden"> 
                    <div className="w-100 overflow-hidden" style={{maxHeight: '150px'}}><GatsbyImage alt="..." image={getImage(imageToUse)} /></div>
                    <div className="flex flex-column pa2">
                        <div className="f8 mb05 ttu orange-900 fw4 ls-md">{node.frontmatter.category}</div>
                        <div className="f5 fw6">{title}</div>
                        <div className="f7 mt075"> {excerpt}</div>
                        <div className="f6  mt05 ttu pointer"><span className="ls05 f7">Read more</span> -&gt;</div>
                    </div>
                </div>
            </Link>
            
          )
        })}

     </div>
    </div>
 </Layout>
    )
  }
  
  export default ContentPage